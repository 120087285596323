import React from 'react'
import { Link } from 'gatsby'

const TagItem = ({tag}) => {    
    return(
        <Link to={`/${tag.slug}`} className="badge badge-primary collection-tag tag">{tag.tag}</Link>
    )
}

export default TagItem
