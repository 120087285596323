import React from 'react'

//components
import CollectionCard from "./collection-card"

const CollectionCards = ({collectionItems}) => {
    const collectionCards = collectionItems.map((collection)=> (<CollectionCard key={(collection.node ? collection.node.slug : collection.slug)} node={(collection.node ? collection.node : collection)}/>))
    
    return(
        <ul className="cards cards-collections">
            {collectionCards}
        </ul>
    )
}

export default CollectionCards
