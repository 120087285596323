import React from 'react'
import { Card } from 'react-bootstrap'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import moment from 'moment'

//components
import TagItem from './tag-item';

const CollectionCard = ({node}) => {
    const Tags = node.tags ? node.tags.map((tag) => (<TagItem key={tag.tag} tag={tag}/>)) : null
    const time = moment(node.createdAt).format("HH:mm - DD MMMM YYYY")
    
    return(
        <li key={node.slug} className="cards-item item">
            <Card className="card-collection">
                <Card.Body>
                    {node.image.fluid!==null &&
                        <Img fluid={node.image.fluid}/>
                    }
                </Card.Body>
                <Card.Footer>
                    <Card.Title><Link to={`/inspiratie/${node.slug}/`} className="stretched-link">{node.title}</Link></Card.Title>
                    <time className="collection-meta" value={node.createdAt}>{time}</time>
                    <div className="card-tags tags">
                        {Tags}
                    </div>
                </Card.Footer>
            </Card>
        </li>
    )
}

export default CollectionCard
